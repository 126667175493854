<template>
  <NuxtLayout name="sticky">
    <template #header>
      <nav class="layout-overlay-header flex items-center px-md h-full">
        <LcButton
          :aria-label="$t('cms.header.button.goback')"
          class="button-back"
          :icon="navIcon"
          size="small"
          :to="backRoute"
          variant="tertiary"
          @click="
            !backRoute &&
            navigateBack({
              previousRoute: previousRoute,
            })
          "
        />

        <h1
          v-if="props.title"
          class="headline w-full text-h3 text-center"
          v-text="props.title"
        />
      </nav>
    </template>

    <template #default>
      <div
        class="layout-overlay-content"
        :class="{ 'with-bottom-navigation': withBottomNavigation }"
      >
        <slot />
      </div>
    </template>

    <template #after>
      <TheFooter />
      <TheBottomNavigation v-if="withBottomNavigation" />
    </template>
  </NuxtLayout>
</template>

<script lang="ts" setup>
import type { RouteLocationRaw } from '#vue-router'

export type OverlayLayoutProps = {
  backRoute?: RouteLocationRaw
  navIcon?: 'arrow-left' | 'close'
  previousRoute?: RouteLocationRaw
  title?: string
  withBottomNavigation?: boolean
}

const props = withDefaults(defineProps<OverlayLayoutProps>(), {
  navIcon: 'close',
})

const { navigateBack } = useRouterUtils()
</script>

<style lang="scss" scoped>
.layout-overlay {
  &-content {
    &.with-bottom-navigation {
      padding-bottom: 1rem;
    }
  }

  &-header {
    position: relative;

    .button-back {
      z-index: 1;
    }

    .headline {
      position: absolute;
      right: 0;
      left: 0;
    }
  }
}
</style>
